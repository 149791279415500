import 'bootstrap/dist/css/bootstrap.min.css';
import Body from './components/body/Body'

function App() {
  return (
    <div className="App">
     <Body/>
    </div>
  );
}

export default App;
